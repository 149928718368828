import './App.css';
// import { env } from './env'

function App() {
	console.log("process.env",process.env)
	console.log("process.env.REACT_APP_ENV1",process.env.REACT_APP_ENV1)
	// console.log("process.env.API_URL",process.env.API_URL)
	return (
		<div style={{ backgroundColor: 'blue' }}>
			{/* <span>{env.REACT_APP_MAIN_TEXT}</span> */}
			<span>Without variable span</span>
			<span>{process.env.REACT_APP_ENV1}</span>
		</div>
	);
}

export default App;
